@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Open+Sans:wght@400;500&family=Poppins:wght@300;400;600&family=Quicksand:wght@400;600&family=Roboto+Condensed:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: var(--section-bgd);
}

body::-webkit-scrollbar-thumb {
  background: var(--nav-blue);
  border-radius: 100vh;
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--nav-blue-shade);
}

html, body {
  overflow-x: hidden;
}

:root {
  --nav-grey: #d4d5d6;
  --nav-deep-grey: #dddddd;
  --nav-blue: #cec2eb;
  --nav-blue-shade: #bea9df;
  --card-blue-shade: #000000 ;
  --nav-deep-blue: #2c2f3f;
  --nav-bgd: #000000;
  --white: #fff;
  --soft-white: #e6d1f2;
  --navy: #0a192f;
  --light-blue: rgb(156, 183, 255);
  --section-bgd: #000000;
}

/* General styles */
.about,
.skills,
.services,
.works,
.contact {
  background-color: var(--section-bgd);
  color: var(--nav-grey);
  width: 100%;
}

.container {
  padding: 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.loader {
  background-color: #070707;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loader-icon {
  color: rgb(237, 27, 209);
  height: 100;
  width: 50;
}

/*  Load animations */
.svg-wrapper {
  position: relative;
}

@keyframes pageLoad {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
  }
  100% {
    stroke-width: 2px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
  }
}
.shape {
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: #b19cd9;
  border: 5px solid #b19cd9;
  animation: 3s ease 0s 1 pageLoad;
}
.text {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 32px;
  letter-spacing: 8px;
  color: rgb(255, 255, 255);
  position: absolute;
  left: 52px;
  top: 10px;
  width: 100%;
  transform: translate(-50% -50%);
}

@media screen and (max-width: 700px) {
  .container {
    padding: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .text {
    font-size: 13px;
    position: absolute;
    left: 40px;
    top: 10px;
  }
  .container {
    padding: 1rem;
  }
}
